import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-vehicles-container" }
const _hoisted_2 = { class: "top-action-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Searchbar),
      _createVNode(_component_Button, {
        text: _ctx.$t('button.addVehicle'),
        primary: true,
        raised: true,
        onOnClick: _ctx.handleShowCreateForm
      }, null, 8, ["text", "onOnClick"])
    ])
  ]))
}