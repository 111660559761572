
import { defineComponent } from "vue";
import {Button, Searchbar} from "@/ui";

export default defineComponent({
    name: "VehicleList",
    components: { Button, Searchbar },
    methods:{
        handleShowCreateForm(){
            this.$router.push("/administration/vehicle/form/0")
        },
    }
})
